import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { fas, faLocationDot,faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import cert from './../assets/icons/certs.png'

library.add( fas, fab, faLocationDot, faEnvelope, faPhone, faFacebookF, faInstagram)

const Footer =  ()=>{
    return (
    <footer className="footer">
          <div className="footer__container">
            <div className="terms">
              <div className="both" style={{'textAlign':'left'}}>
                  <h3 style={{'color':'#fff','margin':0}}>Contactos</h3>
                  <p><span> <FontAwesomeIcon icon="fa-solid fa-location-dot" /> Av das Tulipas 6 Miraflores 
                    Office Center
                    13 Piso salas D/E
                    Algés 1495-161
                    Portugal
                    </span>
                    <br/>
                    <FontAwesomeIcon icon="fa-solid fa-phone" /><a href="tel:+351213873883" style={{'margin':0}}> +351 213873883</a><br/>
                    <FontAwesomeIcon icon="fa-solid fa-envelope" /><a href="mailto:geral@panegara.pt" style={{'margin':0}}> geral@panegara.pt</a>
                  </p>
              </div>
            </div>
            <div className="terms">
                <div className="both">
                  <h3 style={{'color':'#fff','margin':0}}>Empresa Certificada</h3>
                  <ul className="socialmedia">
                      <li style={{'maxWidth':'initial'}}><img  src={cert} alt=""/></li>
                  </ul>
                </div>
              </div>
              <div className="terms">
                <div className="both">
                  <h3 style={{'color':'#fff','margin':0}}>Redes Sociais</h3>
                  <ul className="socialmedia">
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/koka.portugal/"><FontAwesomeIcon style={{'height':'2.3em'}} icon="fa-brands fa-instagram" /></a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/KOKAPortugal/"><FontAwesomeIcon style={{'height':'2.3em'}} icon="fa-brands fa-facebook-f" /></a></li>
                  </ul>
                </div>
              </div>


         </div>
         <div className="copyright">
          <p>
            <a target="_blank" 
               rel="noopener noreferrer" 
               href="https://panegara.pt/"
               style={{'color':'#fff','textDecoration':'none'}}
               >© {(new Date().getFullYear())} Panegara</a>
          </p>
        </div>

    </footer>
    )
  }

export default Footer
