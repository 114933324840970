import React from 'react';
import Home from './pages/home'

// componennts 
import Header from './components/header'
import Footer from './components/footer'


function Wrapper() {
  return (
    <>
      <Header/>
        <Home/>
      <Footer/>
    </>
    );
}

export default Wrapper;
