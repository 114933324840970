import React from "react";

/*Images*/ 


/* Test Imports */ 

import logo from "./../assets/logos/logo.png"

const Header = ()=>{
    return (

              <header className="menu">
              <nav className="navegacao">
                  <div className="navegacao__logo">
                      <a href="#home"><img src={logo} alt=""/></a>
                  </div>

                  <ul className="navegacao__menus menu-inline">
                    <li className="item-inline">
                        <a href="https://www.instagram.com/koka.portugal/">A Koka</a>
                    </li>
                    <li className="item-inline">
                        <a href="https://panegara.pt/shop/category/noodles-koka-12/">Loja</a>
                    </li>
                    <li className="item-inline">
                        <a href="#passatempo">Passatempo</a>
                    </li>
                  </ul>
              </nav>
      </header> 

    )
  }


export default Header
